
button:focus {outline:0;}
input:focus {outline:0;}
img:focus {outline:0;}

.button {
  cursor: pointer;
}

.App {
  background: transparent;
  text-align: center;
  min-width: 375px;
  left: 10vw;
  top: 0;
  font-family: "Verdana", Verdana, Sans-serif;;

}

.background-image {
  animation: blink infinite 5s ease-in-out;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
  opacity: 0.1;
}

.source {
  background: transparent;
  height: 5vh;
  padding: 2vh;
  width: 50%;
}

.input {
  position: relative;
  background-color: honeydew;
  height: 40px;
  width: 40px;
  box-shadow: 0 0 5px 7px honeydew;
  border: transparent;
  border-radius: 10px;
  font-size: large;
  text-align: center;
}

.input2 {
  position: relative;
  background-color: transparent;
  height: 40px;
  width: 70%;
  border: transparent;
  border-radius: 10px;
  font-size: x-large;
  text-align: center;
}
.input2:hover{
   box-shadow: 0 0 5px 7px honeydew;
  background: honeydew;
 }




@media all and (min-width: 1010px) {
  /* For mobile phones: */
  .myCol-30-x {
    background: transparent;
    position: relative;
    width: 25%;
    padding: 5px 5px 5px 5px;
    display: inline-block;
    vertical-align: middle;
  }
  .myCol-30 {
    background: transparent;
    position: relative;
    width: 25%;
    padding: 5px 5px 5px 5px;
    display: inline-block;
    vertical-align: middle;
  }
  .myRow {
    background: transparent;
    width: 100%;
    opacity: 1;
    height: 7vh;
    min-height: 70px;
  }

  .myCol-20 {
    background: transparent;
    position: relative;
    width: 10%;
    padding: 5px 5px 5px 5px;
    display: inline-block;
    vertical-align: top;
  }

  .myCol-80 {
    background: transparent;
    position: relative;
    width: 70%;
    padding: 5px 5px 5px 5px;
    display: inline-block;
  }


}

@media all and (max-width: 1009px) {
  /* For mobile phones: */
  .myCol-30-x {
    width: 90%;
    padding-left: 10%;
    display: block;
    vertical-align: middle;
  }
  .myCol-30 {
    width: 50%;
    display: inline-block;
    vertical-align: middle;
  }
  .myRow {
    background: transparent;
    width: 100%;
    opacity: 1;
    min-height: 70px;
    height: 10vh;
    margin-bottom: 10vh;
  }

  .myCol-20 {
    background: transparent;
    position: relative;
    width: 100%;
    padding: 5px 5px 5px 5px;
    vertical-align: top;
  }

  .myCol-80 {
    background: transparent;
    position: relative;
    width: 100%;
    padding: 5px 5px 5px 5px;
  }
  .App {
    left: 0;
  }
  .for-image {
    margin: auto;
  }
}

.myCol-50 {
  background: transparent;
  position: relative;
  width: 40%;
  padding: 5px 5px 5px 5px;
  display: inline-block;
}

.text {
  font-size: large;
  height: 100%;
}

.alignR {
  text-align: right;
}
.alignL {
  text-align: left;
}
.alignC {
  text-align: center;
}

.image {
  min-width: 70px;
  width: 100%;
  min-height: 70px;
}

.for-image {
  width: 70px;
  height: 70px;
  overflow: hidden;
  align-items: center;
}

@keyframes blink {
  0% {    opacity: 0.1;  }
  50% {    opacity: 0.3;  }
  100% {    opacity: 0.1;  }
}
